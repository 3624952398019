<template>
  <div class="pt-6">
    <div
      v-for="fee in fees"
      :key="fee.id"
      class="flex w-full mb-6"
    >
      <div class="w-1/2 mr-2 text-gray-800">
        {{ fee.name }}
      </div>
      <div class="flex-grow text-gray-800">
        <div class="font-bold">
          <template v-if="fee.active">{{ $n(fee.default, 'currency') }}</template>
          <template v-else>{{ $n(fee.min, 'currency') }}</template>
        </div>
        <!-- <div class="text-xs">
          {{ $t(repeatToString(fee.repeat, fee.repeatUnit), fee.repeat) }}
        </div> -->
      </div>
      <div v-if="fee.canChanged" class="flex-shrink-0 ml-3 -mb-4 h-0">
        <SwitchToggle v-model="fee.active" />
      </div>
    </div>

    <div class="flex flex-shrink w-full mt-12 pb-12">
      <Button class="mr-2 w-full" to="contracts">
        {{ $t('back') }}
      </Button>
      <Button class="ml-2 w-full" primary @click="onContinue">
        {{ $t('continue') }}
      </Button>
    </div>
  </div>
</template>

<script>
import { repeatUnitToString } from '../../../../utils';

export default {
  computed: {
    selectedContract() {
      return this.$store.state.contract.selectedContract;
    },
    fees() {
      return this.$store.state.contract.fees;
    },
  },

  mounted() {
    if (this.selectedContract && this.fees.length === 0) {
      this.$store.commit('updateContractForm', {
        fees: [],
      });
      this.$router.push('starting-conditions');
    }
  },

  methods: {
    onContinue() {
      const fees = this.fees.map((fee) => ({
        id: fee.id,
        amount: fee.active ? fee.default : fee.min,
      }));
      this.$store.commit('updateContractForm', {
        fees,
      });
      this.$router.push('starting-conditions');
    },
    repeatToString(repeat, repeatUnit) {
      return repeatUnitToString(repeat, repeatUnit);
    },
  },
};
</script>
